import axios from "axios";
import Auth from "./store/reducers/Auth";
import { getUser } from "./utills";

const token = localStorage.getItem("access_token");
axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api/`;
axios.defaults.headers.common.Authorization = token ? `Bearer ${token}` : '';

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(({ resolve, reject }) => {
    token ? resolve(token) : reject(error);
  });

  failedQueue = [];
};

axios.interceptors.response.use((response) => response,
  async (error) => {
    const config = error?.config;

    if (error?.response?.status === 403 && !config?.sent) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
        .then((token) => {
          config.headers.Authorization = `Bearer ${token}`;
          return axios(config);
        })
        .catch((err) => Promise.reject(err));
      }

      config.sent = true;
      isRefreshing = true;

      try {
        const accessToken = await refreshToken();
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
        config.headers.Authorization = `Bearer ${accessToken}`;

        processQueue(null, accessToken);
        return axios(config);
      } catch (err) {
        processQueue(err, null);
        return Promise.reject(err);
      } finally {
        isRefreshing = false;
      }
    }

    if (!error?.config.url.includes("/login") && error?.response?.status === 401) {
      handleLogout();
    }
    return Promise.reject(error);
  }
);

async function refreshToken() {
  const res = await axios.post("mobile/refresh", { device_id: getUser().email });
  if (res.data.success) {
    let AuthUser = res.data;

    Auth(
      { isAuthenticated: true, user: AuthUser },
      { type: "AUTH_LOGIN", payload: { access_token: AuthUser.data.access_token, user: AuthUser }}
    );
    return AuthUser.data.access_token;
  }
}

function handleLogout() {
  Auth({ isAuthenticated: false, user: null }, { type: "AUTH_LOGOUT" });
  window.location.href = "/login";
}

export default axios;
